<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="mt-5">
                <p class="text-center heading mt-5">Contattaci</p>
                <hr class="center-bullet" />
            </v-col>

            <v-col cols="12">
                <v-card class="card-padding gradient" height="100%">
                    <h2 class="heading font-weight-bold">Scrivici per qualsiasi informazione</h2>
                    <v-form ref="form">
                        <small>*campi obbligatori</small>
                        <v-row class="mt-2">
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    ref="name"
                                    v-model="name"
                                    name="name"
                                    label="* Nome"
                                    outlined
                                    required
                                    :rules="[() => !!name || 'This field is required']"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    ref="email"
                                    v-model="email"
                                    name="email"
                                    label="* Email"
                                    outlined
                                    required
                                    :rules="emailRules"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    ref="subject"
                                    v-model="subject"
                                    name="subject"
                                    label="* Oggetto"
                                    outlined
                                    required
                                    :rules="[() => !!subject || 'This field is required']"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                                <v-textarea
                                    v-model="message"
                                    name="message"
                                    class="mt-2"
                                    outlined
                                    label="* Messaggio"
                                    required
                                    auto-grow
                                    :rules="[() => !!message || 'This field is required']"
                                ></v-textarea>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                                <v-btn
                                    block
                                    large
                                    class="px-5 mb-5 btn-text"
                                    rounded
                                    color="#3597d3"
                                    dark
                                    @click="sendMail"
                                >
                                    <span v-if="!loading" class="font-weight-bold ma-5">Invia</span>
                                    <v-progress-circular
                                        v-if="loading"
                                        indeterminate
                                        color="white"
                                        width="2"
                                    ></v-progress-circular>
                                </v-btn>
                            </v-col>
                            <v-col v-if="status" cols="12" class="pt-2">
                                Il messaggio è stato inviato!
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    data: function () {
        return {
            loading: false,
            status: false,
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            name: "",
            email: "",
            subject: "",
            message: ""
        };
    },

    methods: {
        sendMail: function () {
            if (this.$refs.form.validate()) {
                let data = {
                    name: this.name,
                    email: this.email,
                    subject: this.subject,
                    message: this.message
                };
                this.postData();
                console.log("To Mail", data);
            }
        },
        async postData() {
            this.status = false;
            this.loading = true;
            let payload = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "api-key": process.env.VUE_APP_API_KEY
            };
            await axios
                .post(
                    "https://api.sendinblue.com/v3/smtp/email",
                    {
                        sender: {
                            name: "Asis CB",
                            email: "gestione@asiscb.it"
                        },
                        to: [
                            {
                                email: "gestione@asiscb.it",
                                name: "Asis CB"
                            }
                        ],
                        subject: "Nuova richiesta di contatto - Asis CB",
                        htmlContent:
                            "<!DOCTYPE html><html><body>" +
                            this.message +
                            "<br>Nome:" +
                            this.name +
                            " <br>Email:" +
                            this.email +
                            "</body></html>"
                    },
                    {
                        headers: payload
                    }
                )
                .then(() => {
                    this.loading = false;
                    this.status = true;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.heading {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 48px;
    color: #3597d3;
}

.sub-title {
    font-family: "Poppins", Regular;
    font-weight: normal;
    font-size: 16px;
}

.gradient {
    box-shadow: 0px 0px 81px #3597d33d !important;
}

hr {
    border: 0;
    height: 2px;
    width: 200px;
    position: relative;
    margin: 30px auto;
}

hr.center-bullet {
    background: #3597d3;
}

hr.center-bullet:before {
    content: "";
    width: 35px;
    height: 10px;
    background: #3597d3;
    display: inline-block;
    border: 2px solid #3597d3;
    border-radius: 28%;
    position: absolute;
    top: -4px;
    left: 42%;
    margin: 0 0 0 -3px;
}

.btn-text {
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
}

@media only screen and (min-width: 1024px) {
    .card-padding {
        padding: 50px;
    }
}

@media only screen and (max-width: 1023px) {
    .card-padding {
        padding: 10px;
    }
}
</style>
