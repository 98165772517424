<template>
    <div>
        <v-container>
            <v-row no-gutters class="my-2">
                <v-btn color="primary" depressed @click="back()">
                    Indietro
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn color="primary" depressed class="mr-2" @click="save"> Salva </v-btn>
            </v-row>

            <v-overlay :value="showOverlay">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-overlay>

            <v-form ref="form">
                <p v-if="showMessage.show" class="text-left red--text">!{{ showMessage.msg }}</p>
                <small>*campi obbligatori</small>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            ref="title"
                            v-model="doc.title"
                            name="title"
                            label="* Titolo"
                            clearable
                            required
                            :rules="[() => !!doc.title || 'This field is required']"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <span> File caricati </span>
                    </v-col>
                    <v-col cols="12">
                        <v-btn
                            :disabled="editMode"
                            color="primary"
                            depressed
                            @click="pickFile"
                        >
                            Seleziona file
                        </v-btn>
                        <v-file-input
                            ref="fileInput"
                            name="fileInput"
                            style="display: none"
                            accept="application/pdf"
                            :rules="fileRules"
                            @change="processUploadFile"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="isDocumentFile" cols="6" lg="3">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    class="chip-overflow"
                                    v-on="on"
                                    @click="downloadUploadedDocument(doc)"
                                >
                                    {{ doc.fileName }}
                                </v-chip>
                            </template>
                            <span>{{ doc.fileName }}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-form>

        </v-container>
    </div>
</template>

<script>
import { EventBus } from "../Event";

export default {
    beforeRouteEnter(to, from, next) {
        if (from.path == "/rsvd/documents") {
            next();
        } else {
            next("/rsvd/documents");
        }
    },

    data: () => {
        return {
            showOverlay: false,
            dialogDelete: false,
            dialogRemoveDocument: false,
            editMode: false,
            documentUrl: "",
            tempdocument: "",
            documents: [],
            documentsToUpload: [],
            uploadedDocument: [],
            documentToRemove: "",
            createdocumentErrorFlag: false,
            showMessage: { show: false, msg: "" },
            fileErrorMessage: { show: false, msg: "" },
            fileRules: [
                (v) => !!v || "File is required",
                (v) => (v && v.size > 0) || "File is required"
            ],
            doc: {
                title: "",
                url: "",
                fileName: "",
                createdAt: "",
                updatedAt: ""
            }
        };
    },

    computed: {
        isDocumentFile: function () {
            if (this.doc.fileName != "") return true;
            else return false;
        }
    },

    mounted: function () {
        EventBus.$on("saveDocument", () => this.save());

        EventBus.$on("deleteDocument", () => this.deleteDocument());

        this.editMode = this.$route.params.id ? true : false;

        if (!this.editMode) {
            this.showMessage.show = false;
            this.showMessage.msg = "";
            this.showOverlay = false;
            this.documentsToUpload = [];
            this.$refs.form.reset();
        } else {
            this.uploadedDocument = this.getUploadedDocument();
            this.doc = this.$route.params.data;
        }

        this.$refs.title.focus();
    },

    created() {},

    beforeDestroy() {
        EventBus.$off("saveDocument");
    },

    methods: {
        back() {
            this.$router.back();
        },

        randomString: function (length) {
            var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var result = "";
            for (var i = length; i > 0; --i) result += str[Math.floor(Math.random() * str.length)];
            return result;
        },

        resetDocumentForm: function () {
            this.$refs.form.reset();
            this.showMessage.show = false;
            this.showMessage.msg = "";
            this.tempdocument = {};
            this.showOverlay = false;
            this.documentDialog = false;
            this.documentsToUpload = "";
        },

        async createDocument() {
            if (!this.doc.fileName) {
                this.showMessage.show = true;
                this.showMessage.msg = "Please select a file to upload";
            } else {
                this.showMessage.show = false;
                this.showMessage.msg = "";
            }
            if (this.$refs.form.validate()) {
                this.showOverlay = true;
                this.createdocumentErrorFlag = false;
                this.showMessage.show = false;
                this.showMessage.msg = "";

                this.$store
                    .dispatch("uploadDocument", this.doc)
                    .then(() => {
                        this.showOverlay = false;
                        this.$router.push("/rsvd/documents").catch(() => true);
                    })
                    .catch((err) => {
                        this.showOverlay = false;
                        console.log(err);
                    });
            }
        },

        updateDocument: function () {
            this.showOverlay = true;
            this.$store
                .dispatch("updateDocument", this.doc)
                .then(() => {
                    this.showOverlay = false;
                    this.$router.push("/rsvd/documents").catch(() => true);
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        pickFile: function () {
            this.$refs.fileInput.$refs.input.click();
        },

        processUploadFile: function (file) {
            if (file != null) {
                this.doc.file = file;
                this.doc.fileName = file.name;
                this.doc.randomStr = this.randomString(6) + file.name;
            }
        },

        getUploadedDocument: function () {
            this.showOverlay = true;
            this.uploadedDocument = [];
            this.$store
                .dispatch("getDocument", this.doc.id)
                .then((res) => {
                    this.uploadedDocument = res;
                    this.showOverlay = false;
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        deleteDocument: function () {
            if (this.editMode) {
                this.dialogDelete = true;
            }
        },

        deleteDocumentConfirm: function () {
            this.closeDelete();
            this.showOverlay = true;
            this.$store
                .dispatch("deleteDocument", this.doc)
                .then(() => {
                    this.showOverlay = false;
                    this.$router.push("/rsvd/documents").catch(() => true);
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        closeDelete: function () {
            this.dialogDelete = false;
        },

        save: function () {
            if (this.editMode) {
                this.updateDocument();
            } else {
                this.createDocument();
            }
        },

        downloadUploadedDocument: function (file) {
            if (this.editMode) {
                window.open(file.url, "_blank");
            }
        }
    }
};
</script>

<style scoped>
.chip-overflow {
    max-width: 200px;
    padding: 2px 5px;
}

::v-deep .v-chip__content {
    padding: 5px;
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

::v-deep .v-chip__close {
    position: absolute;
    top: 5px;
    right: 0;
    width: 24px;
}
</style>
