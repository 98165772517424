import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import UserList from "@/views/Users.vue";
import UserCreate from "@/views/UserCreate.vue";
import UserRead from "@/views/UsersRead.vue";
import UserUpdate from "@/views/UsersUpdate.vue";
import Documents from "@/views/Documents.vue";
import DocumentsRead from "@/views/DocumentsRead.vue";
import DocumentsCreate from "@/views/DocumentsCreate.vue";
import NotFound from "@/views/PageNotFound.vue";
import Store from "../store";
import firebase from "firebase";

Vue.use(VueRouter);

const routes = [
    { path: "/", name: "home", component: Home },
    { path: "/auth", name: "auth", component: Login },
    { path: "/documents", name: "documents-read", component: DocumentsRead },
    { path: "/rsvd/users", name: "users", component: UserList, beforeEnter: admin },
    { path: "/rsvd/users/create", name: "users-create", component: UserCreate, beforeEnter: admin },
    { path: "/rsvd/users/:uid", name: "users-read", component: UserRead, beforeEnter: fauth },
    {
        path: "/rsvd/users/:uid/update",
        name: "users-update",
        component: UserUpdate,
        beforeEnter: admin
    },
    { path: "/rsvd/documents", name: "documents", component: Documents, beforeEnter: admin },
    {
        path: "/rsvd/documents/create",
        name: "documents-create",
        component: DocumentsCreate,
        beforeEnter: admin
    },
    { path: "*", name: "page-not-found", component: NotFound }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

function admin(to, from, next) {
    console.log(Store.getters.isAdminUser)
    console.log(Store.getters.isUserLoggedIn)
    if (Store.getters.isAdminUser) next();
    else next({ name: "auth" });
}

function fauth(to, from, next) {
    firebase.auth().onAuthStateChanged((user) => {
        if (Store.getters.isUserLoggedIn || user) {
            next();
        } else if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = localStorage.getItem("emailForSignIn");
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt("Please provide your email for confirmation");
            }
            // The client SDK will parse the code from the link for you.
            firebase
                .auth()
                .signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    console.log(result);
                    next();
                    // Clear email from storage.
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    console.log(error);
                    next({ name: "auth" });
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        } else {
            console.log("auth");
            next({ name: "auth" });
        }
    });
}

export default router;
