<template>
    <div>
        <v-container>
            <v-row class="mt-15 mb-5" justify="center" align="center">
                <v-col cols="12" lg="6" class="mt-5">
                    <p class="sub-heading">Fondo sanitario integrativo</p>

                    <p class="content">
                        Soggetto privato, costituito a norma di Legge, in associazione no-profit,
                        che offre ai propri iscritti forme di assistenza sanitaria integrativa
                        prestata sia direttamente che indirettamente (fra le quali il rimborso delle
                        spese sanitarie, le rendite da non autosufficienza, le indennità post-mortem
                        ed ulteriori prestazioni appositamente individuate)
                    </p>

                    <v-row class="mt-10">
                        <v-btn
                            rounded
                            color="#2B81D6"
                            dark
                            class="ma-2 btn-text"
                            href="#requirements"
                        >
                            Requisiti necessari
                        </v-btn>
                        <v-btn rounded color="#2B81D6" dark class="ma-2 btn-text" href="#benefits">
                            Vantaggi fiscali
                        </v-btn>
                    </v-row>
                </v-col>

                <v-col cols="12" lg="6">
                    <v-row justify="center" align="center" class="mt-2">
                        <v-avatar class="gradient-man" size="400">
                            <v-img :src="getHappymanImage()" alt="Happy man" />
                        </v-avatar>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <div class="mx-0">
            <v-row>
                <v-col cols="12">
                    <v-img :src="getWaveImage()" alt="Blue wave" />
                </v-col>
            </v-row>
        </div>

        <v-container>
            <v-row class="mt-10">
                <v-col cols="12">
                    <p class="text-center heading mt-5">Asis CB</p>
                    <p class="text-center sub-title blue-text">Perché scegliere noi</p>
                    <hr class="center-bullet" />
                </v-col>
            </v-row>

            <v-row align="stretch" justify="center" class="mb-10">
                <v-col cols="12" lg="6" class="pr-lg-0 mr-lg-0">
                    <v-card width="100%" class="gradient">
                        <v-img :src="getCorporatemanImage()" alt="Corporate man" />
                    </v-card>
                </v-col>

                <v-col cols="12" lg="6" class="pl-lg-0 ml-lg-0">
                    <v-card
                        width="100%"
                        height="100%"
                        class="gradient d-flex flex-column justify-center"
                    >
                        <v-card-text>
                            <p class="title ml-10">Perché Asis CB</p>
                            <p class="content ml-10">
                                Dopo un’approfondita analisi dei principali Fondi «aperti»,
                                caratterizzati da prestazioni predefinite non flessibili, ASIS CB ha
                                scelto di “costruire” assieme ai propri clienti l’insieme delle
                                prestazioni che meglio rispondono alle reali esigenze di ogni
                                singolo caso.
                                <br />
                                In sintesi, ogni programma di assistenza è un unicum flessibile
                                appositamente studiato per l’iscritto.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="mt-15">
                <v-col cols="12">
                    <v-card width="100%" height="100%" class="gradient">
                        <v-card-text id="requirements" class="pa-10">
                            <v-row justify="center" class="ma-lg-12">
                                <p class="heading">Requisiti necessari</p>
                                <p class="content blue-text mt-10" style="text-align: center">
                                    Il Fondo ASIS CB è regolarmente iscritto all'Anagrafe dei Fondi
                                    presso il Ministero della Salute. Gli aderenti al Fondo per
                                    poter beneficiare delle prestazioni da esso offerte devono
                                    essere in regola con il versamento della quota associativa
                                    annuale e con tutti gli adempimenti amministrativi.
                                </p>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="mt-15">
                <v-col cols="12">
                    <h1 class="text-center heading font-weight-bold mt-5">Vantaggi fiscali</h1>
                    <hr class="center-bullet" />
                </v-col>
            </v-row>

            <v-row class="mt-8">
                <v-col cols="12" lg="4">
                    <v-card class="ma-1 ma-lg-0 gradient-35">
                        <v-img :src="getCardWaveImage()">
                            <v-row justify="center" class="pa-5">
                                <v-card-title
                                    class="mt-10 text-center title white--text"
                                    style="text-align: center"
                                >
                                    UNO
                                </v-card-title>

                                <v-card-text class="content">
                                    <div class="mt-8 text-center white--text">
                                        I lavoratori dipendenti possono dedurre parte dei contributi
                                        versati al Fondo fino ad un massimo annuo di €3.615,20,
                                        nonchè le spese mediche non rimborsate dall’assicurazione.
                                    </div>
                                </v-card-text>
                            </v-row>
                        </v-img>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4">
                    <v-card class="ma-1 ma-lg-0 gradient-35">
                        <v-img :src="getCardWaveImage()">
                            <v-row justify="center" class="pa-5">
                                <v-card-title
                                    class="mt-10 text-center title white--text"
                                    style="text-align: center"
                                >
                                    DUE
                                </v-card-title>

                                <v-card-text class="content">
                                    <div class="mt-8 text-center white--text">
                                        Il datore di lavoro paga al dipendente un contributo di
                                        solidarietà del 10% anziché l’aliquota contributiva sociale
                                        obbligatoria, che risulta più elevata.
                                    </div>
                                </v-card-text>
                            </v-row>
                        </v-img>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4">
                    <v-card class="ma-1 ma-lg-0 gradient-35">
                        <v-img :src="getCardWaveImage()">
                            <v-row justify="center" class="pa-5">
                                <v-card-title
                                    class="mt-10 text-center title white--text"
                                    style="text-align: center"
                                >
                                    TRE
                                </v-card-title>

                                <v-card-text class="content">
                                    <div class="mt-8 text-center white--text">
                                        I contributi versati a Fondi e casse possono essere inclusi
                                        nella detrazione dal Reddito di Impresa (IRES) in quanto
                                        costo del lavoro.
                                    </div>
                                </v-card-text>
                            </v-row>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>

            <v-row id="benefits" class="mt-15">
                <v-col cols="12">
                    <h1 class="text-center heading font-weight-bold mt-5">Vantaggi sociali</h1>
                    <hr class="center-bullet" />
                </v-col>
            </v-row>

            <v-row class="mt-8">
                <v-col cols="12" lg="4">
                    <v-card class="ma-1 ma-lg-0 gradient-35">
                        <v-img :src="getCardWaveImage()">
                            <v-row justify="center" class="pa-5">
                                <v-card-title
                                    class="mt-10 text-center title white--text"
                                    style="text-align: center"
                                >
                                    UNO
                                </v-card-title>

                                <v-card-text class="content">
                                    <div class="mt-8 text-center white--text">
                                        Accesso ai servizi esclusi dal Sistema Sanitario Nazionale,
                                        come le prestazioni dentistiche
                                    </div>
                                </v-card-text>
                            </v-row>
                        </v-img>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4">
                    <v-card class="ma-1 ma-lg-0 gradient-35">
                        <v-img :src="getCardWaveImage()">
                            <v-row justify="center" class="pa-5">
                                <v-card-title
                                    class="mt-10 text-center title white--text"
                                    style="text-align: center"
                                >
                                    DUE
                                </v-card-title>

                                <v-card-text class="content">
                                    <div class="mt-8 text-center white--text">
                                        Possibilità di ricevere più di quanto si è versato, poiché
                                        il Fondo è basato sul principio di mutualità
                                    </div>
                                </v-card-text>
                            </v-row>
                        </v-img>
                    </v-card>
                </v-col>

                <v-col cols="12" lg="4">
                    <v-card class="ma-1 ma-lg-0 gradient-35">
                        <v-img :src="getCardWaveImage()">
                            <v-row justify="center" class="pa-5">
                                <v-card-title
                                    class="mt-10 text-center title white--text"
                                    style="text-align: center"
                                >
                                    TRE
                                </v-card-title>

                                <v-card-text class="content">
                                    <div class="mt-8 text-center white--text">
                                        Eliminazione dei tempi di attesa delle strutture sanitarie
                                        pubbliche, potendo usufruire di quelle private
                                    </div>
                                </v-card-text>
                            </v-row>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="mt-15">
            <v-col cols="12">
              <v-card width="100%" height="100%" class="gradient">
                <v-card-text id="team" class="pa-10">
                  <v-row justify="center" class="ma-lg-12">
                    <p class="heading">Chi siamo</p>
                    <p class="content blue-text mt-10" style="text-align: center">
                      Asis Cb è un soggetto privato no-profit, fondato con l’obiettivo
                      di fornire ai propri iscritti i migliori servizi di sanitaria
                      sanitaria integrativa.
                      <br />
                      <br />
                      Il portale è stato costruito sulla base delle esigenze degli associati
                      al servizio e consente di avere sempre una prestazione personalizzata
                      basata sulle proprie necessità.
                      <br />
                      <br />
                      Gli aderenti al Fondo possono beneficiare di vantaggi
                      fiscali e sociali in quanto il Fondo Asis è regolarmente
                      iscritto all’Anagrafe dei Fondi presso il Ministero della Salute.
                    </p>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

            <Contact class="my-15"></Contact>
        </v-container>
    </div>
</template>

<script>
// import Store from "../store";
import Contact from "../components/Contact.vue";

export default {
    /* beforeRouteEnter(to, from, next) {
        if (Store.getters.isUserLoggedIn) {
            next("/rsvd/users");
        } else {
            next();
        }
    }, */

    components: {
        Contact
    },

    methods: {
        getHappymanImage: function () {
            return require("@/assets/images/happy-young-man.png");
        },

        getWaveImage: function () {
            return require("@/assets/images/shape.png");
        },

        getCorporatemanImage: function () {
            return require("@/assets/images/corporate-man.png");
        },

        getCardWaveImage: function () {
            return require("@/assets/images/raggruppa.png");
        }
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.btn-text {
    text-decoration: none;
}

.blue-text {
    color: #3597d3;
}

.btn-text {
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.heading {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: #3597d3;
}

.sub-heading {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 28px;
}

.title {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 24px;
}

.sub-title {
    font-family: "Poppins", Regular;
    font-weight: normal;
    font-size: 16px;
}

.content {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    font-size: 20px;
}

.gradient {
    box-shadow: 0px 0px 59px #3597d33d !important;
}

.gradient-35 {
    box-shadow: 0px 0px 35px #3597d359 !important;
}

.gradient-man {
    box-shadow: 0px 0px 1px 10px white, 5px 5px 81px #3597d33d;
}

.gradient-btn {
    box-shadow: 0px 16px 29px #3597d369;
}

hr {
    border: 0;
    height: 2px;
    width: 200px;
    position: relative;
    margin: 30px auto;
}

hr.center-bullet {
    background: #3597d3;
}

hr.center-bullet:before {
    content: "";
    width: 35px;
    height: 10px;
    background: #3597d3;
    display: inline-block;
    border: 2px solid #3597d3;
    border-radius: 28%;
    position: absolute;
    top: -4px;
    left: 42%;
    margin: 0 0 0 -3px;
}
</style>
