<template>
    <v-container fluid>
        <v-row>
            <v-col v-if="!showOverlay" cols="12">
                <h1 class="text-center heading mt-10">Documentazione</h1>
                <hr class="center-bullet" />
            </v-col>

            <v-overlay :value="showOverlay">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-overlay>

            <v-col v-for="(doc, index) in documents" :key="index" cols="12" lg="4">
                <v-card flat height="350" class="ma-1 ma-lg-0">
                    <v-img :src="getImagePath(index)">
                      <v-row no-gutters class="r">
                        <v-col cols="12" class="c flex row no-gutters align-center">
                          <v-card-text
                            class="text-h5 font-weight-bold"
                            style="align: center"
                        >
                            <div class="text-center white--text">
                                {{ doc.title }}
                            </div>
                        </v-card-text>
                        </v-col>
                        <v-col cols="12" class="c flex row no-gutters align-center">
                          <v-card-actions class="center">
                            <v-btn large class="px-5" rounded @click="download(doc)">
                              <p :class="getBtnColor(index)" class="btn-text ma-5">Download</p>
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-img>
                </v-card>
            </v-col>

            <Contact class="mt-10 mb-15"></Contact>
        </v-row>
    </v-container>
</template>

<script>
import Contact from "../components/Contact.vue";

export default {
    components: {
        Contact
    },

    data: function () {
        return {
            showOverlay: false,
            documents: [],
            ImageCounter: -1,
            colorCounter: -1,
            images: [
                "green.png",
                "orange.png",
                "red.png",
                "pink.png",
                "yellow.png",
                "green_dark.png",
                "blue.png"
            ],
            colors: [
                "green-txt",
                "orange-txt",
                "red-txt",
                "pink-txt",
                "yellow-txt",
                "green_dark-txt",
                "blue-txt"
            ]
        };
    },

    mounted: function () {
        this.getDocuments();
    },

    methods: {
        getDocuments: function () {
            this.showOverlay = true;
            this.documents = [];

            this.$store
                .dispatch("getDocumentList")
                .then((res) => {
                    this.documents = [...res];
                    this.showOverlay = false;
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        getImagePath: function (index) {
            let counter = index;
            if (index > this.images.length - 1) {
                counter = index % this.images.length;
            }
            return require("@/assets/images/" + this.images[counter]);
        },

        getBtnColor: function (index) {
            let counter = index;
            if (index > this.colors.length - 1) {
                counter = index % this.colors.length;
            }
            return this.colors[counter];
        },

        download: function (doc) {
            window.open(doc.url, "_blank");
        }
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

button{
  margin: auto;
}

.r{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.c{
  flex:1;
}

.center{
  margin: auto;
}

.heading {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 48px;
    color: #3597d3;
}

.sub-title {
    font-family: "Poppins", Regular;
    font-weight: normal;
    font-size: 16px;
}

.btn-text {
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 21px;
}

.green-txt {
    color: #9fe83e;
}

.orange-txt {
    color: #fc8c28;
}

.red-txt {
    color: #f04a48;
}

.pink-txt {
    color: #d71e73;
}

.yellow-txt {
    color: #f5bd2d;
}

.green_dark-txt {
    color: #3aac80;
}

.blue-txt {
    color: #7481b0;
}

.card-text {
    margin-top: 15%;
}

.card-actions {
    position: absolute;
    bottom: 0;
    margin-left: 25%;
    margin-bottom: 50px;
}

hr {
    border: 0;
    height: 2px;
    width: 200px;
    position: relative;
    margin: 30px auto;
}

hr.center-bullet {
    background: #3597d3;
}

hr.center-bullet:before {
    content: "";
    width: 35px;
    height: 10px;
    background: #3597d3;
    display: inline-block;
    border: 2px solid #3597d3;
    border-radius: 28%;
    position: absolute;
    top: -4px;
    left: 42%;
    margin: 0 0 0 -3px;
}

.gradient {
    box-shadow: 0px 0px 81px #3597d33d !important;
}
</style>
