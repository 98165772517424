import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/storage";

require("firebase/firestore");

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {},
    state: {
        status: "",
        userIntials: "",
        photoUrl: "",
        route: localStorage.getItem("route") || null,
        uid: localStorage.getItem("uid") || "",
        adminUser: localStorage.getItem("adminUser") || false,
        displayname: localStorage.getItem("displayname") || "no-image.png",
        profileImage: localStorage.getItem("profileImage") || ""
    },
    mutations: {
        loginRequest(state) {
            state.status = "loading";
        },

        loginSuccess(state, uid) {
            state.status = "success";
            state.uid = uid;
        },

        loginError(state) {
            state.status = "error";
        },

        logoutUser(state) {
            state.uid = "";
            localStorage.removeItem("uid");
            localStorage.removeItem("adminUser");
        },

        setAdminUser(state, isAdminUser) {
            state.adminUser = isAdminUser;
            localStorage.setItem("adminUser", isAdminUser);
        },

        setPhotoUrl(state, url) {
            state.photoUrl = url;
        },

        setLanguage(state, language) {
            state.language = language;
        },

        setDisplayname(state, displayname) {
            state.displayname = displayname;
        },

        setProfileImage(state, profileImage) {
            state.profileImage = profileImage;
        },

        setUserIntials(state, userIntials) {
            state.userIntials = userIntials;
        },

        setRoute(state, route) {
            state.route = route;
            localStorage.setItem("route", route);
        },

        requestSend(state) {
            state.status = "loading";
        },

        requestSuccess(state) {
            state.status = "success";
        },

        requestFail(state) {
            state.status = "error";
        }
    },
    actions: {
        // Signin with email and password
        login({ commit }, loginData) {
            return new Promise((resolve, reject) => {
                commit("loginRequest");
                firebase
                    .auth()
                    .signInWithEmailAndPassword(loginData.email, loginData.password)
                    .then((userCredential) => {
                        let uid = userCredential.user.uid;
                        firebase.firestore().collection("users").doc(uid).update({
                            lastAccessedAt: moment().toDate()
                        });
                        firebase
                            .firestore()
                            .collection("users")
                            .doc(uid)
                            .get()
                            .then((res) => {
                                let role = res.data()["role"];
                                if (role === "admin") commit("setAdminUser", true);
                                else commit("setAdminUser", false);
                                commit("loginSuccess", uid);
                                localStorage.setItem("uid", uid);
                                resolve(userCredential);
                            });
                    })
                    .catch((error) => {
                        commit("loginError");
                        localStorage.removeItem("uid");
                        reject(error);
                    });
            });
        },

        // Signin with email and password
        loginLink({ commit }, email) {
            return new Promise((resolve, reject) => {
                commit("loginRequest");
                firebase
                    .auth()
                    .signInWithEmailLink(email, window.location.href)
                    .then((userCredential) => {
                        let uid = userCredential.user.uid;
                        firebase.firestore().collection("users").doc(uid).update({
                            lastAccessedAt: moment().toDate()
                        });
                        let role = userCredential.user.role;
                        if (role === "admin") commit("setAdminUser", true);
                        else commit("setAdminUser", false);
                        commit("loginSuccess", uid);
                        localStorage.setItem("uid", uid);
                        resolve(userCredential);
                    })
                    .catch((error) => {
                        commit("loginError");
                        localStorage.removeItem("uid");
                        reject(error);
                    });
            });
        },

        // Fetch user details
        getUserDetails({ commit }, uid) {
            return new Promise((resolve, reject) => {
                commit("requestSend");
                firebase
                    .firestore()
                    .collection("users")
                    .doc(uid)
                    .get()
                    .then((user) => {
                        if (user.data()) {
                            commit("requestSuccess");
                            resolve(user.data());
                        } else {
                            reject();
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Get User Data with Email
        getUserWithMail({ commit }, email) {
            return new Promise((resolve, reject) => {
                commit("requestSend");

                let userDataRef = firebase
                    .firestore()
                    .collection("users")
                    .where("email", "==", email);

                userDataRef
                    .get()
                    .then((querySnapshot) => {
                        if (querySnapshot.docs.length > 0) {
                            querySnapshot.forEach(function (doc) {
                                if (doc.data()) {
                                    resolve(doc.data());
                                } else {
                                    reject("No User Record found for this Email");
                                }
                            });
                        } else {
                            reject("No User Record found for this Email");
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Send signin link to the user Email
        sendSigninMail({ commit }, userData) {
            return new Promise((resolve, reject) => {
                commit("requestSend");
                // Call cloud functions
                const opt = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        email: userData.email,
                        url: userData.url.url
                    })
                };
                fetch("https://us-central1-cb-asis.cloudfunctions.net/SendLink", opt)
                    .then((response) => {
                        console.log(response.json());
                        console.log(userData.email);
                        localStorage.emailForSignIn = userData.email;
                        resolve();
                    })
                    .catch((err) => {
                        console.log("error", err);
                        reject(err);
                    });
            });
        },

        // Update user access
        updateUserAccess({ commit }, uid) {
            return new Promise((resolve, reject) => {
                commit("requestSend");
                firebase
                    .firestore()
                    .collection("users")
                    .doc(uid)
                    .update({
                        lastAccessedAt: moment().toDate()
                    })
                    .then(() => {
                        commit("requestSuccess");
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Create user with email and password
        createAuthUser({ commit }, userData) {
            return new Promise((resolve, reject) => {
                commit("loginRequest");
                firebase
                    .auth()
                    .createUserWithEmailAndPassword(userData.email, userData.password)
                    .then((userCredential) => {
                        commit("requestSuccess");
                        userData.uid = userCredential.uid;
                        resolve(userData);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Update user with uid
        updateUser({ commit }, userData) {
            return new Promise((resolve, reject) => {
                commit("requestSend");
                firebase
                    .firestore()
                    .collection("users")
                    .doc(userData.uid)
                    .update({
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        code: userData.code,
                        notes: userData.notes ? userData.notes : "",
                        updatedAt: moment().toDate()
                    })
                    .then(() => {
                        commit("requestSuccess");
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Retrieve the User List
        getUserList({ commit }) {
            return new Promise((resolve, reject) => {
                commit("requestSend");
                let userList = [];
                firebase
                    .firestore()
                    .collection("users")
                    .onSnapshot((querySnapshot) => {
                        querySnapshot.forEach(
                            (doc) => {
                                userList.push({
                                    uid: doc.id,
                                    email: doc.data().email,
                                    code: doc.data().code,
                                    firstName: doc.data().firstName,
                                    lastName: doc.data().lastName,
                                    notes: doc.data().notes,
                                    imageUrl: doc.data().imageUrl,
                                    files: doc.data().files,
                                    documentsUploadedAt: doc.data().documentsUploadedAt
                                        ? doc.data().documentsUploadedAt.toDate()
                                        : "",
                                    lastAccessedAt: doc.data().lastAccessedAt
                                        ? doc.data().lastAccessedAt.toDate()
                                        : "",
                                    notificationSentAt: doc.data().notificationSentAt
                                        ? doc.data().notificationSentAt.toDate()
                                        : "",
                                    createdAt: doc.data().createdAt.toDate()
                                });
                            },
                            (error) => {
                                console.log(error);
                                commit("requestFail");
                                reject(error);
                            }
                        );
                        commit("requestSuccess");
                        resolve(userList);
                    });
            });
        },

        // Upload the user file and map the url with users collection
        uploadFile({ commit }, data) {
            commit("requestSend");
            return new Promise((resolve, reject) => {
                firebase
                    .storage()
                    .ref("files")
                    .child(data.uid + "/" + data.randomStr)
                    .put(data)
                    .then(async () => {
                        const fileUrl = await firebase
                            .storage()
                            .ref("files")
                            .child(data.uid + "/" + data.randomStr)
                            .getDownloadURL();

                        // map file with user
                        await firebase
                            .firestore()
                            .collection("users")
                            .doc(data.uid)
                            .update({
                                documentsUploadedAt: moment().toDate()
                                /* files: firebase.firestore.FieldValue.arrayUnion({
                                    file: data.randomStr,
                                    url: fileUrl
                                }) */
                            })
                            .then(async () => {
                                await firebase
                                    .firestore()
                                    .collection("users")
                                    .doc(data.uid)
                                    .collection("files")
                                    .add({
                                        file: data.randomStr,
                                        url: fileUrl
                                    });
                                commit("requestSuccess");
                                resolve();
                            })
                            .catch((error) => {
                                console.log("err", error);
                                reject(error);
                            });

                        commit("requestSuccess");
                        resolve(fileUrl);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // Fetch the list of files for a user with id
        getUserFiles({ commit }, uid) {
            commit("requestSend");
            return new Promise((resolve, reject) => {
                let files = [];
                firebase
                    .firestore()
                    .collection("users")
                    .doc(uid)
                    .collection("files")
                    .onSnapshot((querySnapshot) => {
                        querySnapshot.forEach(
                            (doc) => {
                                files.push({
                                    file: doc.data().file,
                                    url: doc.data().url
                                });
                            },
                            (error) => {
                                console.log("err", error);
                                reject(error);
                            }
                        );

                        commit("requestSuccess");
                        resolve(files);
                    });
            });
        },

        // Upload the document and save the URL
        uploadDocument({ commit }, data) {
            commit("requestSend");
            return new Promise((resolve, reject) => {
                firebase
                    .storage()
                    .ref("documents" + "/" + data.randomStr)
                    .put(data.file)
                    .then(async () => {
                        const documentUrl = await firebase
                            .storage()
                            .ref("documents" + "/" + data.randomStr)
                            .getDownloadURL();

                        firebase
                            .firestore()
                            .collection("documents")
                            .add({
                                title: data.title,
                                fileName: data.randomStr,
                                url: documentUrl,
                                createdAt: moment().toDate(),
                                updatedAt: moment().toDate()
                            })
                            .then(() => {
                                commit("requestSuccess");
                                resolve();
                            })
                            .catch((err) => {
                                console.log("err", err);
                                reject(err);
                            });
                    })
                    .catch((err) => {
                        console.log("err", err);
                        reject(err);
                    });
            });
        },

        // Retrieve the User List
        getDocumentList({ commit }) {
            return new Promise((resolve, reject) => {
                commit("requestSend");
                let docList = [];
                firebase
                    .firestore()
                    .collection("documents")
                    .onSnapshot((querySnapshot) => {
                        querySnapshot.forEach(
                            (doc) => {
                                docList.push({
                                    id: doc.id,
                                    url: doc.data().url,
                                    title: doc.data().title,
                                    fileName: doc.data().fileName,
                                    updatedAt: doc.data().updatedAt.toDate(),
                                    createdAt: doc.data().createdAt.toDate()
                                });
                            },
                            (error) => {
                                console.log(error);
                                commit("requestFail");
                                reject(error);
                            }
                        );
                        commit("requestSuccess");
                        resolve(docList);
                    });
            });
        },

        // Get the list of documents from the documents collection
        getDocument({ commit }, id) {
            commit("requestSend");
            return new Promise((resolve, reject) => {
                firebase
                    .firestore()
                    .collection("documents")
                    .doc(id)
                    .get()
                    .then((file) => {
                        commit("requestSuccess");
                        resolve(file.data());
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // Update the document title
        updateDocument({ commit }, data) {
            commit("requestSend");
            return new Promise((resolve, reject) => {
                firebase
                    .firestore()
                    .collection("documents")
                    .doc(data.id)
                    .update({
                        title: data.title,
                        updatedAt: moment().toDate()
                    })
                    .then(() => {
                        commit("requestSuccess");
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Delete the uploaded document and the record from the collection
        deleteDocument({ commit }, data) {
            commit("requestSend");
            return new Promise((resolve, reject) => {
                firebase
                    .firestore()
                    .collection("documents")
                    .doc(data.id)
                    .delete()
                    .then(async () => {
                        firebase
                            .storage()
                            .ref("documents/" + data.fileName)
                            .delete()
                            .then(() => {
                                commit("requestSuccess");
                                resolve();
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        // User Signout
        logout({ commit }) {
            console.log("signout");
            return new Promise((resolve, reject) => {
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        commit("logoutUser");
                        resolve();
                    })
                    .catch((error) => {
                        commit("loginError");
                        localStorage.removeItem("uid");
                        reject(error);
                    });
            });
        }
    },
    getters: {
        isUserLoggedIn: (state) => !!state.uid,
        isAdminUser: (state) => !!state.adminUser,
        userLoginStatus: (state) => state.status,
        getUserIntials: (state) => state.userIntials,
        getDisplayname: (state) => state.displayname,
        getPhotoUrl: (state) => state.photoUrl,
        getRoute: (state) => state.route
    }
});
