<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-overlay :value="showOverlay">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-overlay>
                <v-card outlined>
                    <v-list v-show="!showSigninForm">
                        <v-list-item>
                            <v-list-item-content>
                                <v-toolbar color="primary" dark flat dense>
                                    <v-toolbar-title>Accedi con email e password</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-text>
                                    <v-form ref="form">
                                        <p v-if="showMessage.show" class="text-left red--text">
                                            !{{ showMessage.msg }}
                                        </p>

                                        <v-text-field
                                            ref="email"
                                            v-model="email"
                                            label="Email"
                                            name="email"
                                            prepend-icon="mdi-email"
                                            type="text"
                                            :rules="emailRules"
                                            :error-messages="loginValidation.email.error_msg"
                                            required
                                            @keyup.enter="login"
                                        ></v-text-field>

                                        <v-text-field
                                            ref="password"
                                            v-model="password"
                                            label="Password"
                                            name="password"
                                            prepend-icon="mdi-lock"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="showPassword ? 'text' : 'password'"
                                            :rules="[() => !!password || 'This field is required']"
                                            :error-messages="loginValidation.password.error_msg"
                                            required
                                            @click:append="showPassword = !showPassword"
                                            @keyup.enter="login"
                                        ></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text @click="toSigninForm">Accedi come utente</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="resetForm">Reset</v-btn>
                                    <v-btn color="primary" depressed @click="login">Accedi</v-btn>
                                </v-card-actions>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-list v-show="showSigninForm">
                        <v-list-item>
                            <v-list-item-content>
                                <v-toolbar color="primary" dark flat dense>
                                    <v-toolbar-title>Accedi con la tua e-mail</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-text>
                                    <v-form ref="signinForm">
                                        <p
                                            v-if="showSigninMessage.show"
                                            class="text-left red--text"
                                        >
                                            !{{ showSigninMessage.msg }}
                                        </p>
                                        <p v-if="signinSuccess.show" class="text-left green--text">
                                            {{ signinSuccess.msg }}
                                        </p>

                                        <v-text-field
                                            ref="signinEmail"
                                            v-model="signinEmail"
                                            label="e-mail"
                                            name="signinEmail"
                                            prepend-icon="mdi-email"
                                            type="text"
                                            :rules="emailRules"
                                            required
                                            @keyup.enter="sendMail"
                                        ></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text @click="toLoginForm">Amministratore</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" depressed @click="sendMail">
                                        Invia e-mail di verifica
                                    </v-btn>
                                </v-card-actions>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {

    data: () => ({
        uid: "",
        imageUrl: "",
        userIntials: "",
        showOverlay: false,
        showPassword: false,
        showSigninForm: true,
        loggedIn: false,
        loginDialog: false,
        email: "",
        password: "",
        signinEmail: "",
        route: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        loginValidation: {
            email: { status: true, error_msg: "" },
            password: { status: true, error_msg: "" },
            general: { status: true, error_msg: "" }
        },
        showMessage: { show: false, msg: "" },
        showSigninMessage: { show: false, msg: "" },
        signinSuccess: { show: false, msg: "" },
        formHasErrors: false
    }),

    methods: {
        login: function () {
            if (this.$refs.form.validate()) {
                this.showOverlay = true;
                let user = {
                    email: this.email,
                    password: this.password
                };
                this.$store
                    .dispatch("login", user)
                    .then((res) => {
                      if (res) {
                            this.resetForm();
                            // this.uid = data.user.uid;
                            console.log(res);
                            this.showOverlay = false;
                            this.$router.push({ name: "users" }).catch(() => true);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        let error = err.message ? err.message : "";
                        if (error != "") {
                            this.showOverlay = false;
                            this.showMessage.show = true;
                            this.showMessage.msg = error;
                        }
                    });
            }
        },

        resetForm: function () {
            this.$refs.form.reset();
            this.showMessage.show = false;
            this.showMessage.msg = "";
            this.loginDialog = false;
        },

        resetSigninForm: function () {
            this.$refs.signinForm.reset();
            this.showSigninMessage.show = false;
            this.showSigninMessage.msg = "";
            this.signinSuccess.show = false;
            this.signinSuccess.msg = "";
            this.loginDialog = false;
        },

        sendMail: function () {
            if (this.$refs.signinForm.validate()) {
                this.showOverlay = true;
                this.$store
                    .dispatch("getUserWithMail", this.signinEmail)
                    .then((res) => {
                        let userData = res;

                        const actionCodeSettings = {
                            url: `${location.origin}/rsvd/users/` + userData.uid,
                            handleCodeInApp: true
                        };

                        let data = {
                            email: userData.email,
                            url: actionCodeSettings
                        };

                        this.$store
                            .dispatch("sendSigninMail", data)
                            .then(() => {
                                this.showOverlay = false;
                                this.resetSigninForm();
                                this.signinSuccess.show = true;
                                this.signinSuccess.msg = "Controlla la tua casella di posta per accedere";
                            })
                            .catch((err) => {
                                this.showOverlay = false;
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        this.showOverlay = false;
                        if (err) {
                            this.signinSuccess.show = false;
                            this.signinSuccess.msg = "";
                            this.showSigninMessage.show = true;
                            this.showSigninMessage.msg = err;
                        }
                    });
            }
        },

        toSigninForm: function () {
            this.resetForm();
            this.showSigninForm = true;
        },

        toLoginForm: function () {
            this.resetSigninForm();
            this.showSigninForm = false;
        },

    }
};
</script>
