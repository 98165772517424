<template>
    <div>
        <v-toolbar class="gradient px-5" light flat>
            <v-toolbar-title>
                <router-link to="/">
                    <v-img src="@/assets/images/logo.png" width="150" />
                </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <div v-if="!admin" class="hidden-sm-and-down">
                <v-btn class="mx-2 link-text" text to="/"> Home </v-btn>
                <v-btn class="mx-2 link-text" text to="/documents"> Documentazione </v-btn>
                <v-btn v-if="!auth" class="mx-2 link-text" text to="/auth"> Area Riservata </v-btn>
                <v-btn v-else class="mx-2 link-text" text :to="'/rsvd/users/' + auth.uid"> Area Riservata </v-btn>
                <v-btn v-if="auth" class="mx-2 link-text" outlined @click="logout"> Logout </v-btn>
            </div>

            <div v-else class="hidden-sm-and-down">
                <v-btn class="mx-2 link-text" text to="/"> Home </v-btn>
                <v-btn class="mx-2 link-text" text to="/rsvd/users"> Utenti </v-btn>
              <v-btn class="mx-2 link-text" text to="/rsvd/documents"> Documentazione </v-btn>
                <v-btn class="mx-2 link-text" outlined @click="logout"> Logout </v-btn>
            </div>

            <div class="hidden-md-and-up">
                <v-menu nudge-width="300" offset-y offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-app-bar-nav-icon></v-app-bar-nav-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item to="/">
                            <v-list-item-title> Home </v-list-item-title>
                        </v-list-item>

                        <v-list-item to="/documents">
                            <v-list-item-title> Documentazione </v-list-item-title>
                        </v-list-item>

                        <v-list-item to="/login">
                            <v-list-item-title> Area Riservata </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-toolbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
require("firebase/auth");

export default {
    data: function () {
        return {
            auth: null
      };
    },
    created() {
        firebase.auth().onAuthStateChanged((user) => {
            this.auth = user;
        });
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "isUserLoggedIn",
            admin: "isAdminUser"
        })
    },
    methods: {
        logout: function () {
            this.$store
                .dispatch("logout")
                .then(() => {
                    this.auth = null
                    this.$router.push({ name: "auth" });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

a:hover {
    text-decoration: none;
}

.gradient {
    box-shadow: 0px 0px 81px #3597d33d !important;
}

.link-text {
    font-family: "Poppins", sans-serif;
    font-size: 14px !important;
    font-weight: 700;
    text-transform: capitalize;
}

.v-toolbar__items > .v-btn {
    height: 40px !important;
}

.v-btn--active {
    color: #ffffff !important;
}

.v-btn--active >>> .v-btn__content {
    color: #3597d3 !important;
}
</style>
