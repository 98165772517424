<template>
    <v-container fluid>
        <v-row>

            <v-col class="pa-0" cols="12">
                <v-card flat>
                    <v-card-title>
                        <v-btn color="primary" depressed @click="create"> Aggiungi nuovo </v-btn>
                    </v-card-title>

                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            placeholder="Cerca per Email, Nome, Cognome"
                            outlined
                            dense
                            hide-details
                        ></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="users"
                        :search="search"
                        :loading="isLoading"
                        :options="dataOptions"
                        :footer-props="footerOptions"
                        loading-text="Loading... Please wait"
                        sort-by="createdAt"
                        :sort-desc="true"
                        class="row-pointer"
                        :custom-sort="customSort"
                        @click:row="update"
                    >
                        <template v-slot:[`item.createdAt`]="{ item }">
                            <span>{{ item.createdAt | dateFormatter }}</span>
                        </template>
                        <template v-slot:[`item.documentsUploadedAt`]="{ item }">
                            <span>{{ item.documentsUploadedAt | dateFormatter }}</span>
                        </template>
                        <template v-slot:[`item.lastAccessedAt`]="{ item }">
                            <span>{{ item.lastAccessedAt | dateFormatter }}</span>
                        </template>
                        <template v-slot:[`item.notificationSentAt`]="{ item }">
                            <span>{{ item.notificationSentAt | dateFormatter }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from "moment";
import { EventBus } from "../Event";

export default {
    filters: {
        dateFormatter: function (dateStr) {
            if (dateStr) return moment(dateStr).format("DD-MM-YYYY HH:mm:ss");
            else return "-";
        }
    },

    data: () => ({
        isLoading: true,
        uid: "",
        search: "",
        user: {},
        users: [],
        footerOptions: {
            "items-per-page-options": [10, 25, 50, 100]
        },
        dataOptions: {
            itemsPerPage: 25
        },
        headers: [
            { text: "Nome", value: "firstName" },
            { text: "Cognome", value: "lastName" },
            { text: "Codice Fiscale", align: "start", sortable: false, value: "code" },
            { text: "Email", value: "email" },
            { text: "Documenti caricati", value: "documentsUploadedAt", align: "center" },
            { text: "Ultimo accesso", value: "lastAccessedAt", align: "center" },
        ]
    }),

    mounted: function () {
        EventBus.$on("createUser", () => this.create());

        this.getUsers();
    },

    created() {
      this.getUsers();
    },

    methods: {
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == "createdAt") {
                    if (isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
            });
            return items;
        },

        update(item) {
            this.$router.push({ name: "users-update", params: { uid: item.uid } });
        },

        create: function () {
            this.user = {};
            this.$router
                .push({
                    path: "users/create",
                    params: { data: this.user }
                })
                .catch(() => true);
        },

        getUsers: function () {
            this.users = [];
            this.isLoading = true;
            this.$store
                .dispatch("getUserList")
                .then((res) => {
                    this.users = [...res];
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        }
    }
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}

.v-application--is-ltr .row-pointer >>> .v-data-footer__select {
    margin-left: 0px !important;
}

.v-data-table.row-pointer.theme--light.v-data-table >>> .v-data-footer {
    justify-content: center !important;
}
</style>
