<template>
    <v-footer dark padless>
        <v-row justify="center" no-gutters>
            <v-col class="text-center" cols="12">
                <v-card light flat tile class="py-2 mb-0 px-5">
                    <v-card-text class="text-left font-weight-bold">
                        <!-- {{ new Date().getFullYear() }} — <strong>Copyrights Reserved</strong> -->
                        &copy; 2021 Asis CB
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
        icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"]
    })
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
