<template>
    <v-app>
        <Header></Header>

        <v-main class="pt-10">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </v-main>

        <Footer></Footer>
    </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer
    },
    data: () => ({
        //
    })
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
</style>
