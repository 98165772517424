<template>
    <div>
        <v-container>
            <v-overlay :value="showOverlay">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-overlay>

            <v-form ref="form">
                <v-row class="mt-5">
                    <v-col cols="12" lg="6">
                        <v-text-field
                            ref="name"
                            v-model="user.firstName"
                            name="firstName"
                            label="* Nome"
                            clearable
                            required
                            :disabled="true"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="user.lastName"
                            name="lastName"
                            label="* Cognome"
                            clearable
                            required
                            :disabled="true"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="user.code"
                            name="code"
                            label="* Codice Fiscale"
                            clearable
                            required
                            :disabled="true"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="user.email"
                            name="email"
                            label="* Email"
                            required
                            clearable
                            :disabled="true"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <v-row>
                <v-col v-if="uploadedFiles.length > 0" cols="12">
                    <p>Download File(s)</p>

                    <v-row class="mt-2">
                        <div v-for="(file, index) in uploadedFiles" :key="index" no-gutters>
                            <v-chip v-if="file" class="ma-2" @click="downloadUploadedFile(file)">
                                {{ file.file }}
                            </v-chip>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import firebase from "firebase";

export default {
    data: function () {
        return {
            showOverlay: false,
            uploadedFiles: [],
            user: {}
        };
    },

    mounted: function () {
        this.uid = this.$route.params.uid;
        this.fetchUserDetails(this.$route.params.uid);
    },

    created() {
        console.log(firebase.auth().currentUser);
    },

    methods: {
        fetchUserDetails: function () {
            this.showOverlay = true;
            this.$store
                .dispatch("getUserDetails", this.uid)
                .then((res) => {
                    this.user = res;
                    this.$store
                        .dispatch("updateUserAccess", this.uid)
                        .then(() => {
                            this.getUploadedFiles();
                        })
                        .catch((err) => {
                            this.showOverlay = false;
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getUploadedFiles: function () {
            this.showOverlay = true;
            this.uploadedFiles = [];
            this.$store
                .dispatch("getUserFiles", this.uid)
                .then((res) => {
                    this.uploadedFiles = [...res];
                    this.showOverlay = false;
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        downloadUploadedFile: function (file) {
            window.open(file.url, "_blank");
        }
    }
};
</script>
