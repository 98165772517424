<template>
    <v-container fluid>
        <v-row>
            <v-col class="pa-0" cols="12">
                <v-card flat>
                    <v-card-title>
                        <v-btn color="primary" depressed @click="create"> Aggiungi nuovo </v-btn>
                    </v-card-title>

                    <v-data-table
                        :headers="headers"
                        :items="documents"
                        :search="search"
                        :loading="isLoading"
                        :options="dataOptions"
                        :footer-props="footerOptions"
                        loading-text="Loading... Please wait"
                        sort-by="createdAt"
                        :sort-desc="true"
                        class="doc-row-pointer"
                        :custom-sort="customSort"
                        @click:row="download"
                    >
                        <template v-slot:[`item.createdAt`]="{ item }">
                            <span>{{ item.createdAt | dateFormatter }}</span>
                        </template>
                        <template v-slot:[`item.updatedAt`]="{ item }">
                            <span>{{ item.updatedAt | dateFormatter }}</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small @click="deleteDocument(item)"> mdi-delete </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="subtitle-1">
                    L'eliminazione è irreversibile, sei sicuro di voler procedere?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteDocumentConfirm">Conferma</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from "moment";
import { EventBus } from "../Event";

export default {
    filters: {
        dateFormatter: function (dateStr) {
            if (dateStr) return moment(dateStr).format("DD-MM-YYYY HH:mm:ss");
            else return dateStr;
        }
    },

    data() {
        return {
            dialogDelete: false,
            dialogDeleteDoc: null,
            isLoading: false,
            search: "",
            document: {},
            documents: [],
            footerOptions: {
                "items-per-page-options": [10, 25, 50, 100]
            },
            dataOptions: {
                itemsPerPage: 25
            },
            headers: [
                { text: "Titolo", value: "title", width: "50%" },
                { text: "Creato il", value: "createdAt", width: "25%" },
                { text: "Aggiornato alle", value: "updatedAt", width: "25%" },
                { text: "Elimina", align: 'center', value: "actions", sortable: false }
            ]
        };
    },

    mounted: function () {
        EventBus.$on("createDocument", () => this.create());

        this.getDocuments();
    },

    methods: {
        randomString: function (length) {
            var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var result = "";
            for (var i = length; i > 0; --i) result += str[Math.floor(Math.random() * str.length)];
            return result;
        },

        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == "createdAt") {
                    if (isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
            });
            return items;
        },

        remove: function (uid) {
            this.document = {};
            console.log(uid);
        },

        create: function () {
            this.document = {};
            this.$router
                .push({
                    name: "documents-create"
                })
                .catch(() => true);
        },

        editDocument: function (doc) {
            let document = Object.assign({}, doc);
            let id = doc.id;
            this.$router.push({
                name: "document-edit",
                params: { id: id, data: document }
            });
        },

        getDocuments: function () {
            this.documents = [];
            this.isLoading = true;
            this.$store
                .dispatch("getDocumentList")
                .then((res) => {
                    this.documents = [...res];
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },

        deleteDocument: function (doc) {
            this.dialogDelete = true;
            this.dialogDeleteDoc = doc;
        },

        deleteDocumentConfirm: function () {
            this.closeDelete();
            this.showOverlay = true;
            this.$store
                .dispatch("deleteDocument", this.dialogDeleteDoc)
                .then(() => {
                    this.showOverlay = false;
                    this.getDocuments();
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        closeDelete: function () {
            this.dialogDelete = false;
        },

        download: function (doc) {
            window.open(doc.url, "_blank");
        }
    }
};
</script>

<style scoped>
.doc-row-pointer >>> tbody tr :hover {
    cursor: pointer;
}

.v-application--is-ltr .doc-row-pointer >>> .v-data-footer__select {
    margin-left: 0px !important;
}

.v-data-table.doc-row-pointer.theme--light.v-data-table >>> .v-data-footer {
    justify-content: center !important;
}
</style>
