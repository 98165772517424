import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";

require("firebase/auth");

Vue.prototype.moment = moment;
Vue.config.productionTip = false;

// Firebase Configurations
var firebaseConfig = {
    apiKey: "AIzaSyCRWYKutL0MqGDk2wTPiy3Kru93x-wYALA",
    authDomain: "cb-asis.firebaseapp.com",
    projectId: "cb-asis",
    storageBucket: "cb-asis.appspot.com",
    messagingSenderId: "736719343604",
    appId: "1:736719343604:web:8741d271c6a9a3fc6433a1",
    measurementId: "G-FC4BFX5XS7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount("#app");
