var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":_vm.create}},[_vm._v(" Aggiungi nuovo ")])],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","placeholder":"Cerca per Email, Nome, Cognome","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"loading":_vm.isLoading,"options":_vm.dataOptions,"footer-props":_vm.footerOptions,"loading-text":"Loading... Please wait","sort-by":"createdAt","sort-desc":true,"custom-sort":_vm.customSort},on:{"click:row":_vm.update},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatter")(item.createdAt)))])]}},{key:"item.documentsUploadedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatter")(item.documentsUploadedAt)))])]}},{key:"item.lastAccessedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatter")(item.lastAccessedAt)))])]}},{key:"item.notificationSentAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatter")(item.notificationSentAt)))])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }