<template>
    <div>
        <v-container>
            <v-row no-gutters class="pb-4">
                <v-btn color="primary" depressed @click="back()"> Indietro </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined depressed class="ma-2" @click="notifyUser">
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        width="2"
                        size="20"
                        color="primary"
                    ></v-progress-circular>
                    <span v-else>Invia Notifica</span>
                </v-btn>
                <v-btn color="primary" depressed class="ma-2" @click="save"> Salva </v-btn>
                <v-btn color="error" outlined class="ma-2" @click="deleteUser"> Elimina </v-btn>
            </v-row>

            <v-overlay :value="showOverlay">
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-overlay>

            <v-form ref="form">
                <p v-if="showMessage.show" class="text-left red--text">!{{ showMessage.msg }}</p>
                <small>*campi obbligatori</small>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-text-field
                            ref="name"
                            v-model="user.firstName"
                            name="firstName"
                            label="* Nome"
                            clearable
                            required
                            error-messages=""
                            :rules="[() => !!user.firstName || 'This field is required']"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="user.lastName"
                            name="lastName"
                            label="* Cognome"
                            clearable
                            required
                            error-messages=""
                            :rules="[() => !!user.lastName || 'This field is required']"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="user.code"
                            name="code"
                            label="* Codice Fiscale"
                            clearable
                            required
                            error-messages=""
                            :rules="[() => !!user.code || 'This field is required']"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-text-field
                            v-model="user.email"
                            name="email"
                            disabled
                            label="* Email"
                            required
                            clearable
                            error-messages=""
                            :rules="emailRules"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-textarea
                            v-model="user.notes"
                            name="notes"
                            class="mt-2"
                            outlined
                            label="Nota"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>

            <v-row>
                <v-col cols="12">
                    <span class="mr-2">File caricati</span>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" depressed @click="pickFile"> Seleziona file </v-btn>
                    <v-file-input
                        ref="fileInput"
                        name="fileInput"
                        style="display: none"
                        accept="application/pdf"
                        @change="processUploadFile"
                    ></v-file-input>
                </v-col>

                <v-col cols="12">
                    <v-row class="mt-2">
                        <div v-for="(file, index) in uploadedFiles" :key="index" no-gutters>
                            <v-chip
                                v-if="file"
                                class="ma-4"
                                close
                                @click="downloadUploadedFile(file)"
                                @click:close="removeFile(file)"
                            >
                                {{ file.file }}
                            </v-chip>
                        </div>
                    </v-row>
                </v-col>
            </v-row>

            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="subtitle-1">
                        L'eliminazione è irreversibile, sei sicuro di voler procedere?
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogRemoveFile" max-width="500px">
                <v-card>
                    <v-card-title class="subtitle-1">
                        L'eliminazione è irreversibile, sei sicuro di voler procedere?
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeRemoveFile">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="removeFileConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from "../Event";
import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import axios from "axios";
require("firebase/firestore");

export default {
    data: () => {
        return {
            loading: false,
            isLoading: false,
            showOverlay: false,
            dialogDelete: false,
            dialogRemoveFile: false,
            fileUrl: "",
            tempUser: "",
            file: [],
            filesToUpload: [],
            uploadedFiles: [],
            fileToRemove: "",
            createUserErrorFlag: false,
            showMessage: { show: false, msg: "" },
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"
            ],
            user: {
                code: "",
                email: "",
                firstName: "",
                notes: "",
                lastName: "",
                imageUrl: "",
                files: [],
                documentsUploadedAt: "",
                notificationSentAt: "",
                lastAccessedAt: "",
                createdAt: "",
                updatedAt: ""
            }
        };
    },

    mounted: function () {
        EventBus.$on("saveUser", () => this.save());

        EventBus.$on("deleteUser", () => this.deleteUser());

        EventBus.$on("notifyUser", () => this.notifyUser());

        this.isLoading = true;
        this.user.uid = this.$route.params.uid;
        this.$store
            .dispatch("getUserDetails", this.user.uid)
            .then((res) => {
                this.user = res;
                this.isLoading = false;
            })
            .catch((err) => {
                this.isLoading = false;
                console.log(err);
            });

        this.getUploadedFiles();
    },

    created() {},

    beforeDestroy() {
        EventBus.$off("saveUser");
    },

    methods: {
        back() {
            this.$router.back();
        },

        randomString: function (length) {
            var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var result = "";
            for (var i = length; i > 0; --i) result += str[Math.floor(Math.random() * str.length)];
            return result;
        },

        resetUserForm: function () {
            this.$refs.form.reset();
            this.showMessage.show = false;
            this.showMessage.msg = "";
            this.tempUser = {};
            this.showOverlay = false;
            this.userDialog = false;
            this.filesToUpload = "";
        },

        createUser: async function () {
            if (this.$refs.form.validate()) {
                this.showOverlay = true;
                this.createUserErrorFlag = false;
                this.showMessage.show = false;
                this.showMessage.msg = "";
                let tempUser = this.user;
                await firebase
                    .auth()
                    .createUserWithEmailAndPassword(this.user.email, this.randomString(8))
                    .then(
                        (cred) => {
                            this.tempCredUser = cred.user;
                            firebase.firestore().collection("users").doc(cred.user.uid).set({
                                email: cred.user.email,
                                uid: cred.user.uid,
                                code: tempUser.code,
                                firstName: tempUser.firstName,
                                lastName: tempUser.lastName,
                                notes: tempUser.notes,
                                role: "user",
                                imageUrl: "",
                                documentsUploadedAt: "",
                                notificationSentAt: "",
                                lastAccessedAt: "",
                                createdAt: moment().toDate(),
                                updatedAt: moment().toDate()
                            });
                        },
                        (err) => {
                            this.createUserErrorFlag = true;
                            let error = err.message ? err.message : "";
                            if (error !== "") {
                                this.showMessage.show = true;
                                this.showMessage.msg = error;
                                this.showOverlay = false;
                            }
                        }
                    )
                    .then(async () => {
                        if (!this.createUserErrorFlag) {
                            /* const actionCodeSettings = {
                    url: `${location.origin}/rsvd/user/` + this.tempCredUser.uid,
                    handleCodeInApp: true
                };
                await firebase
                    .auth()
                    .sendSignInLinkToEmail(this.user.email, actionCodeSettings)
                    .catch((err) => {
                        console.log("error", err);
                    }); */
                            this.$router.push({ name: "users" }).catch(() => true);
                        }
                    });
            }
        },

        updateUser: function () {
            this.showOverlay = true;
            this.$store
                .dispatch("updateUser", this.user)
                .then(() => {
                    this.showOverlay = false;
                    this.$router.push("/rsvd/users").catch(() => true);
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        pickFile: function () {
            this.$refs.fileInput.$refs.input.click();
        },

        processUploadFile: function (file) {
            if (file != null) {
                this.file = file;
                this.file.randomStr = this.randomString(6) + this.file.name;
                this.file.uid = this.user.uid;
                this.uploadFile(this.user.uid, this.file);
            }
        },

        uploadFile: function (uid, file) {
            this.showOverlay = true;
            this.$store
                .dispatch("uploadFile", file)
                .then((res) => {
                    this.file.url = res;
                    this.showOverlay = false;
                    this.getUploadedFiles();
                })
                .catch((err) => {
                    console.log("err", err);
                });
        },

        getUploadedFiles: function () {
            this.showOverlay = true;
            this.uploadedFiles = [];
            this.$store
                .dispatch("getUserFiles", this.user.uid)
                .then((res) => {
                    this.uploadedFiles = [...res];
                    this.showOverlay = false;
                })
                .catch((err) => {
                    this.showOverlay = false;
                    console.log(err);
                });
        },

        deleteUser: function () {
            this.dialogDelete = true;
        },

        deleteUserConfirm: function () {
            this.closeDelete();
            this.showOverlay = true;
            firebase
                .firestore()
                .collection("users")
                .doc(this.user.uid)
                .delete()
                .then(async () => {
                    const storageRef = firebase.storage().ref("files").child(this.user.uid);
                    storageRef.listAll().then((listResults) => {
                        const promises = listResults.items.map((item) => {
                            return item.delete();
                        });
                        Promise.all(promises);
                    });
                    this.showOverlay = false;
                    this.$router.push("/rsvd/users").catch(() => true);
                });
        },

        closeDelete: function () {
            this.dialogDelete = false;
        },

        save: function () {
            this.updateUser();
        },

        downloadUploadedFile: function (file) {
            window.open(file.url, "_blank");
        },

        closeRemoveFile: function () {
            this.dialogRemoveFile = false;
            this.fileToRemove = "";
        },

        removeFile: function (file) {
            this.dialogRemoveFile = true;
            this.fileToRemove = file;
        },

        removeFileConfirm: function () {
            this.removeUploadedFile(this.fileToRemove);
            this.closeRemoveFile();
        },

        removeFilesToUpload: function (file) {
            this.filesToUpload.splice(
                this.filesToUpload.findIndex((a) => a.name === file.name),
                1
            );
        },

        removeUploadedFile: async function (file) {
            this.showOverlay = true;
            let filesRef = firebase
                .firestore()
                .collection("users")
                .doc(this.user.uid)
                .collection("files")
                .where("file", "==", file.file);

            await filesRef
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        doc.ref.delete();
                    });
                })
                .catch(function (error) {
                    this.showOverlay = false;
                    console.error("Error removing document: ", error);
                });

            firebase
                .storage()
                .ref("files")
                .child(this.user.uid + "/" + file.file)
                .delete()
                .then(() => {
                    this.getUploadedFiles();
                    this.showOverlay = false;
                })
                .catch(function (error) {
                    this.showOverlay = false;
                    console.error("Error removing file: ", error);
                });
        },

        notifyUser: async function () {
            this.status = false;
            this.loading = true;
            let payload = {
                Accept: "application/json",
                "Content-Type": "application/json",
                "api-key": process.env.VUE_APP_API_KEY
            };
            await axios
                .post(
                    "https://api.sendinblue.com/v3/smtp/email",
                    {
                        sender: {
                            name: "Asis CB",
                            email: "gestione@asiscb.it"
                        },
                        to: [
                            {
                                email: this.user.email,
                                name: "Asis CB"
                            }
                        ],
                        subject: "Nuovi documenti per te - Asis CB",
                        htmlContent:
                            "<!DOCTYPE html><html><body>Sono stati caricati nuovi documenti all'interno della tua area personale. <br> Per visualizzarli accedi al tuo profilo sul portale <a href='https://asiscb.it' target='_blank'>AsisCB</a>. </body></html>"
                    },
                    {
                        headers: payload
                    }
                )
                .then(async () => {
                    await new Promise((r) => setTimeout(r, 1000));
                    this.loading = false;
                    this.status = true;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped></style>
